import { assertHasServerError } from "store/actions";

import { getClientSideApiFetcher } from "helpers/apis/apiFetcher";
import { PayloadForComponent } from "helpers/utils/payloadFormats";

import apiMessages from "constants/apiMessages";

export const handlePayloadAndNotify = async (
  payload,
  setBulkConfigs,
  {
    interceptSuccess = null,
    interceptFailure = null,
    successCallback = null,
    failureCallback = null,
    fieldErrorPreMessage = null,
  } = {}
) => {
  let messages;
  let isActive = true;
  let bulkConfigs;
  const setIsActive = (val) =>
    setBulkConfigs((state) => ({ ...state, isActive: val }));
  const { success, response } = payload;
  const { message, error_type, body, status_code } = response;

  if (success) {
    if (message) messages = message;
    else isActive = false;
  } else {
    /* Evaluate if error is of a displayable type. */
    if ([403, 500, 502].includes(status_code)) isActive = false;
    else {
      if (error_type === "request_error") messages = message;
      else if (error_type === "field_error") {
        messages = fieldErrorPreMessage
          ? [fieldErrorPreMessage]
          : [apiMessages.problemWithInputFields];
        messages = messages.concat(
          body.map((entry) => Object.values(entry)[0])
        );
      }
    }
  }

  bulkConfigs = {
    success,
    messages,
    isActive,
    setIsActive,
    closable: true,
    autoClose: false,
  };

  bulkConfigs = success
    ? interceptSuccess
      ? interceptSuccess(bulkConfigs)
      : bulkConfigs
    : interceptFailure
    ? interceptFailure(bulkConfigs)
    : bulkConfigs;

  setBulkConfigs((state) => ({ ...state, ...bulkConfigs }));

  if (success && successCallback) await successCallback();
  else if (failureCallback) await failureCallback();
};

// TODO - Evaluate if redundant
export const handleNotifyByBulk = (success, messages = "", setBulkConfigs) => {
  const setIsActive = (val) =>
    setBulkConfigs((state) => ({ ...state, isActive: val }));

  const bulkConfigs = {
    success,
    messages,
    isActive: true,
    setIsActive,
    closable: true,
    autoClose: false,
  };
  setBulkConfigs((state) => ({ ...state, ...bulkConfigs }));
};

async function apiPayloadSorter(fetch) {
  // TODO - PayloadForComponent to become endpointPayloadSorter
  const payload = new PayloadForComponent();
  try {
    const { data } = await fetch();
    payload.as(data.success, data);
  } catch (error) {
    if (error.response) payload.as(false, error.response.data);
    else payload.as(false, { status_code: 500 });
  }
  return payload;
}

const apiConfig: {
  [key: string]: {
    method: "get" | "post";
    endpoint: string;
    returnFormat?: "flat" | "standard";
  };
} = {
  change_or_select_plan: {
    method: "post",
    endpoint: "api/account/personal/change_or_select_plan/",
  },
  confirm_email: {
    method: "post",
    endpoint: "api/auth/confirm_email/",
  },
  reset_confirmation_code: {
    method: "post",
    endpoint: "api/auth/send_email_confirmation_code/",
  },
  contact_media: {
    method: "post",
    endpoint: "api/services/contact_media/",
  },
  account_change_or_select_plan: {
    method: "post",
    endpoint: "api/account/personal/change_or_select_plan/",
  },
  account_create_plan_user: {
    method: "post",
    endpoint: "api/account/personal/create_plan_user/",
  },
  account_dismiss_payment_reminder: {
    method: "post",
    endpoint: "api/account/personal/dismiss_payment_reminder/",
  },
  account_update_plan_status: {
    method: "post",
    endpoint: "api/account/update_plan_status/",
  },
  organisations_get_organisation_data: {
    method: "get",
    endpoint: "api/organisations/get_organisation_data/",
  },
  organisations_vote: {
    method: "post",
    endpoint: "api/organisations/vote/",
  },
  personal_user_details: {
    method: "get",
    endpoint: "api/account/personal/preferences/get_details/",
  },
  personal_marketing_preferences: {
    method: "get",
    endpoint:
      "api/account/personal/preferences/get_or_update_marketing_preferences/",
  },
  personal_payment_details: {
    method: "get",
    endpoint: "api/account/personal/preferences/get_payment_details/",
  },
  personal_delete_user: {
    method: "post",
    endpoint: "api/account/personal/delete_user/",
  },
  personal_update_details: {
    method: "post",
    endpoint: "api/account/personal/preferences/update_details/",
  },
  personal_update_email: {
    method: "post",
    endpoint: "api/auth/update_email/",
  },
  personal_update_password: {
    method: "post",
    endpoint: "api/auth/get_or_update_password/",
  },
  personal_remove_card: {
    method: "post",
    endpoint: "api/account/personal/preferences/remove_payment_details/",
  },
  personal_cancel_plan: {
    method: "post",
    endpoint: "api/account/personal/cancel_plan/",
  },
  personal_edit_add_card: {
    method: "get",
    endpoint: "api/account/personal/preferences/setup_new_payment_details/",
  },
  preferences_update_personal_email: {
    method: "post",
    endpoint: "api/account/personal/preferences/update_personal_email/",
  },
  auth_field_validation: {
    method: "post",
    endpoint: "api/auth/field_validation/",
    returnFormat: "flat",
  },
  auth_get_csrf_token: {
    method: "get",
    endpoint: "api/auth/get_csrf_token/",
    returnFormat: "flat",
  },
  auth_get_password: {
    method: "get",
    endpoint: "api/auth/get_or_update_password/",
  },
  auth_get_user: {
    method: "get",
    endpoint: "api/auth/get_user/",
    returnFormat: "flat",
  },
  auth_login: {
    method: "post",
    endpoint: "api/auth/login/",
  },
  auth_logout: {
    method: "post",
    endpoint: "api/auth/logout/",
  },
  auth_send_password_reset_code: {
    method: "post",
    endpoint: "api/auth/send_password_reset_code/",
  },
  auth_send_password_reset_code_flat: {
    method: "post",
    endpoint: "api/auth/send_password_reset_code/",
    returnFormat: "flat",
  },
  auth_enter_password_reset_code: {
    method: "post",
    endpoint: "api/auth/enter_password_reset_code/",
    returnFormat: "flat",
  },
  auth_enter_new_password: {
    method: "post",
    endpoint: "api/auth/enter_new_password/",
  },
  services_add_subscriber: {
    method: "post",
    endpoint: "api/services/add_subscriber/",
    returnFormat: "flat",
  },
  services_get_plan_data: {
    method: "get",
    endpoint: "api/services/get_plan_data/",
  },
};

type APIRoute = {
  fetch: (body?, options?: { params?: any; method?: "get" | "post" }) => any;
};

const api: { [routeName: string]: APIRoute } = {};

for (const [key, config] of Object.entries(apiConfig)) {
  const { method: defaultMethod, endpoint, returnFormat = "standard" } = config;

  api[key] = {
    fetch: async function (body, options = { params: null, method: null }) {
      const { params, method } = options;
      const finalEndpoint = endpoint + (params ? "?" + params : "");
      const finalMethod = method || defaultMethod;
      const fetchBuild = async () =>
        await getClientSideApiFetcher()[finalMethod](finalEndpoint, body);
      if (returnFormat === "standard") {
        return await apiPayloadSorter(fetchBuild);
      } else {
        try {
          const response = await fetchBuild();
          return response.data;
        } catch (error) {
          try {
            return error.response.data;
          } catch (error) {
            assertHasServerError(
              true,
              "Network Error",
              "Unable to complete this request"
            );
          }
        }
      }
    },
  };
}

Object.freeze(api);
export default api;
