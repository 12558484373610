import React from "react";

import { useLoadingEffectRouter } from "helpers/utils/routing";

type LinkWithLoadingProps = {
  href: string;
  children: React.ReactNode;
  className?: string;
};

const LinkWithLoading = ({
  href,
  children,
  className = "",
}: LinkWithLoadingProps) => {
  const navigateWithLoading = useLoadingEffectRouter();
  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigateWithLoading(href);
  };
  return (
    <a href={href} className={`cursor-pointer ${className}`} onClick={onClick}>
      {children}
    </a>
  );
};

export default LinkWithLoading;
