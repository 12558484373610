import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";

import { Asset } from "contentful";

import { Theme } from "types/ui";

import {
  convertUnderScoreToHyphen,
  getThemeFormatValue,
} from "helpers/utils/formatting";
import { getThemeCookie, setThemeCookie } from "helpers/utils/general";

type NavbarStyleType = {
  navbarColor?: string;
  navbarLogo?: string;
  navbarMobileHamburgerIconColor?: string;
  navbarTabletHamburgerIconColor?: string;
};

type FooterStyleType = {
  showShortFooter?: boolean;
  footerBackgroundColor?: string;
};

type UIContextType =
  | {
      // Theme
      theme: Theme;
      themeDarkColor: string;
      themePrimaryColor: string;
      themeHyphen: string;
      setTheme: (theme: Theme) => void;
      // Navbar
      navbarColor: string;
      navbarLogo: string;
      navbarMobileHamburgerIconColor: string;
      navbarTabletHamburgerIconColor: string;
      setNavbarStyle: (navbarStyle: NavbarStyleType) => void;
      // OrganisationLogo
      navbarOrganisationLogo: Asset;
      setNavbarOrganisationLogo: (organisationLogo: Asset) => void;
      // Footer
      showShortFooter: boolean;
      footerBackgroundColor: string;
      setFooterStyle: (footerStyle: FooterStyleType) => void;
      // Loading
      isLoading: boolean;
      setIsLoading: (isLoading: boolean) => void;
      // Video autoplay
      autoPlayingVideo: string;
      setAutoPlayingVideo: (autoPlayingVideo: string) => void;
    }
  | undefined;

const UIContext = createContext<UIContextType>(undefined);

type ContextProviderProps = {
  children: ReactNode;
};

export const UIContextProvider = ({ children }: ContextProviderProps) => {
  // --- User group state ---
  //This handles the "skinning" of the app.
  const [theme, _setTheme] = useState<Theme>(Theme.wilderness);
  const themeDarkColor = getThemeFormatValue(theme, "darkColor");
  const themePrimaryColor = getThemeFormatValue(theme, "hexcode");
  const themeHyphen = convertUnderScoreToHyphen(theme);

  // This is a fix to prevent user group being set to undefined.
  // The long term solution is to find the call that is setting it as undefined
  const setTheme = (theme?: Theme) => {
    _setTheme(theme ?? Theme.wilderness);
  };

  // Set the theme using the theme cookie before we do any layout
  // to avoid flicker between different themes. We can't provide this as
  // an initialiser to useState because †he cookies aren't
  // available on the server for every page
  useLayoutEffect(() => {
    setTheme(getThemeCookie());
  }, []);

  // Keep the userGroup cookie in sync with the userGroup state
  useEffect(() => {
    setThemeCookie(theme);
  }, [theme]);

  // --- Navbar state ---
  // It is the responsibility of each individual page to
  // set the navbar styles it needs
  useEffect(() => {
    return () => {
      setNavbarOrganisationLogo(null);
    };
  }, []);
  const [navbarColor, setNavbarColor] = useState<string>("white");
  const [navbarLogo, setNavbarLogo] = useState<string>("primary");
  const [navbarOrganisationLogo, setNavbarOrganisationLogo] =
    useState<Asset | null>(null);
  const [navbarMobileHamburgerIconColor, setNavbarMobileHamburgerIconColor] =
    useState<string>("white");
  const [navbarTabletHamburgerIconColor, setNavbarTabletHamburgerIconColor] =
    useState<string>("white");

  const setNavbarStyle = (newNavbarStyle: NavbarStyleType) => {
    if (newNavbarStyle.navbarColor !== undefined)
      setNavbarColor(newNavbarStyle.navbarColor);
    if (newNavbarStyle.navbarLogo !== undefined)
      setNavbarLogo(newNavbarStyle.navbarLogo);
    if (newNavbarStyle.navbarMobileHamburgerIconColor !== undefined)
      setNavbarMobileHamburgerIconColor(
        newNavbarStyle.navbarMobileHamburgerIconColor
      );
    if (newNavbarStyle.navbarTabletHamburgerIconColor !== undefined)
      setNavbarTabletHamburgerIconColor(
        newNavbarStyle.navbarTabletHamburgerIconColor
      );
  };

  // --- Footer state ---
  // It is the responsibility of each individual page to
  // set the footer styles it needs
  const [footerBackgroundColor, setFooterColor] = useState<string>("navy");
  const [showShortFooter, setShowShortFooter] = useState<boolean>(false);

  const setFooterStyle = (newFooterStyle: FooterStyleType) => {
    if (newFooterStyle.footerBackgroundColor !== undefined)
      setFooterColor(newFooterStyle.footerBackgroundColor);
    if (newFooterStyle.showShortFooter !== undefined)
      setShowShortFooter(newFooterStyle.showShortFooter);
  };

  // --- Loading state ---
  // This is the global loading state that is used to show
  // a global loading bar
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // --- Video AutoPlaying State ---
  const [autoPlayingVideo, setAutoPlayingVideo] = useState("");

  return (
    <UIContext.Provider
      value={{
        // User group
        theme: theme,
        themeDarkColor: themeDarkColor,
        themePrimaryColor: themePrimaryColor,
        themeHyphen: themeHyphen,
        setTheme: setTheme,
        // Navbar
        navbarColor,
        navbarLogo,
        navbarMobileHamburgerIconColor,
        navbarTabletHamburgerIconColor,
        setNavbarStyle,
        // OrganisationLogo
        navbarOrganisationLogo,
        setNavbarOrganisationLogo,
        // Footer
        footerBackgroundColor,
        showShortFooter,
        setFooterStyle,
        // Loading
        isLoading,
        setIsLoading,
        // Video Autoplay
        autoPlayingVideo,
        setAutoPlayingVideo,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUIContext = () => {
  const context = useContext(UIContext);

  if (context === undefined) {
    throw new Error("useUIContext must be used within a UIContextProvider");
  }

  return context;
};
