// TODO all this functions must be Evaluated if really needed or not (seems like not)
// - Proper test to be written for those that are needed
import { TypeCheckingError } from "helpers/utils/customErrors";

export const isString = (val) =>
  Object.prototype.toString.call(val) === "[object String]";

// TODO - Evaluate -- Seems redundant in face of truthy default checking.
export const isEmpty = (val) =>
  isString(val) ? val === "" : Array.isArray(val) ? val.length > 0 : false;

export const isDefined = (val) => typeof val !== "undefined";

/* Throws Errors or Returns value */
export const throwsErrorOrReturnsValue = (val, result, errorMessage) => {
  if (result === false) {
    throw new TypeCheckingError(errorMessage);
  } else return val;
};
export const getDefinedOrError = (val, errorMessage) =>
  throwsErrorOrReturnsValue(
    val,
    isDefined(val),
    errorMessage || "Type error: not defined"
  );

export const getNotEmptyOrError = (val, errorMessage) =>
  throwsErrorOrReturnsValue(
    val,
    isEmpty(val),
    errorMessage || "Type error: not defined"
  );
