export class AuthenticationError extends Error {
  constructor(...params) {
    super(...params);
    this.name = "AuthenticationError";
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, AuthenticationError);
  }
}

export class CsrfTokenError extends Error {
  constructor(...params) {
    super(...params);
    this.name = "CsrfTokenError";
    if (Error.captureStackTrace) Error.captureStackTrace(this, CsrfTokenError);
  }
}

// TODO - Evaluate if this error type complies with js
export class NetworkError extends Error {
  constructor(...params) {
    super(...params);
    this.name = "NetworkError";
    if (Error.captureStackTrace) Error.captureStackTrace(this, NetworkError);
  }
}

export class WrongRequestError extends Error {
  constructor(...params) {
    super(...params);
    this.name = "WrongRequestError";
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, WrongRequestError);
  }
}

export class WrongResponseError extends Error {
  constructor(...params) {
    super(...params);
    this.name = "WrongResponseError";
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, WrongResponseError);
  }
}

export class BackEndError extends Error {
  constructor(...params) {
    super(...params);
    this.name = "BackEndError";
    if (Error.captureStackTrace) Error.captureStackTrace(this, BackEndError);
  }
}

export class NotFoundError extends Error {
  constructor(...params) {
    super(...params);
    this.name = "NotFoundError";
    if (Error.captureStackTrace) Error.captureStackTrace(this, NotFoundError);
  }
}

export class TypeCheckingError extends TypeError {
  constructor(...params) {
    super(...params);
    this.name = "TypeCheckingError";
    if (Error.captureStackTrace)
      Error.captureStackTrace(this, TypeCheckingError);
  }
}
