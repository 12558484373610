import { useState } from "react";

import Link from "next/dist/client/link";
import Image from "next/image";

import {
  IconArrowRight,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconCircleCheck,
  IconX,
} from "@tabler/icons";

import { useUIContext } from "contexts/UIContext";
import { useUserContext } from "contexts/UserContext";

import api from "helpers/apis/api";
import { Colors } from "helpers/utils/uiSetup";

import Section from "components/containers/Section";
import PinwheelPrimaryLogo from "components/logo/PinwheelPrimaryLogo";
import {
  NavigationLink,
  getFooterLinks,
} from "components/navigation/navbar/links";

const FooterLink = ({ label, route }: NavigationLink) => {
  return (
    <Link href={route}>
      <a className="text-white text-body16 font-medium leading-7 sm:leading-5 lg:leading-7 text-center sm:text-left">
        {label}
      </a>
    </Link>
  );
};

type ExpandedFooterProps = {
  backgroundColor: string;
};

const ExpandedFooter = ({ backgroundColor }: ExpandedFooterProps) => {
  const { isAuthenticated } = useUserContext();
  const { explore, learn, support, social } = getFooterLinks(isAuthenticated);
  const { setIsLoading } = useUIContext();

  const [email, setEmail] = useState("");
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    color: "",
  });

  const submitEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const response = await api.services_add_subscriber.fetch({ email });
    setIsLoading(false);
    if (response.success && response.message) {
      setEmail("");
      setResponseMessage({ message: response.message, color: "success-green" });
      setTimeout(() => {
        setResponseMessage({ message: "", color: "" });
      }, 3000);
    } else if (!response.success && response.error_type === "request_error") {
      setResponseMessage({ message: response.message, color: "error-red" });
      setTimeout(() => {
        setResponseMessage({ message: "", color: "" });
      }, 3000);
    }
  };

  return (
    <div
      className={`${backgroundColor} py-10`}
      style={{ transition: "background 1s cubic-bezier(0.16, 1, 0.3, 1)" }}
    >
      <Section>
        <footer className="flex flex-col w-full gap-y-10">
          <div className="flex flex-col sm:flex-row justify-between">
            <Link href="/">
              <a>
                <div className="cursor-pointer max-w-[60px] mx-auto sm:mx-0">
                  <PinwheelPrimaryLogo />
                </div>
              </a>
            </Link>
            <div className="flex flex-1 sm:flex-none justify-center items-center mt-10 sm:mt-0">
              <div className="flex flex-row gap-x-8">
                <a href={social.instagram} target="_blank" rel="noreferrer">
                  <IconBrandInstagram color={Colors.oceanRescue} stroke={1.5} />
                </a>
                <a href={social.twitter} target="_blank" rel="noreferrer">
                  <IconBrandTwitter color={Colors.oceanRescue} stroke={1.5} />
                </a>
                <a href={social.linkedIn} target="_blank" rel="noreferrer">
                  <IconBrandLinkedin color={Colors.oceanRescue} stroke={1.5} />
                </a>
              </div>
            </div>
          </div>
          <hr className="border border-black" />
          <div className="flex flex-col sm:flex-row">
            <div className="flex-1 flex flex-col justify-between">
              <div className="flex flex-col w-full text-center sm:text-left sm:w-1/2 gap-y-8">
                <p className="text-white text-body16 text-normal">
                  Pinwheel empowers businesses, their customers and employees to
                  repair our planet
                </p>
                <a
                  href="mailto:contact@pinwheel.earth"
                  className="text-wilderness text-body16 font-bold"
                >
                  contact@pinwheel.earth
                </a>
              </div>
              <div className="flex flex-row items-center sm:gap-14 mt-12 justify-around sm:justify-start">
                <a href="https://www.goodbusinesscharter.com/">
                  <Image
                    src="/img/marks/good_business_charter_white.png"
                    width="96px"
                    height="75px"
                    alt="The logo of the Good Business Charter standard"
                  />
                </a>
              </div>
            </div>
            <div className="flex-1 flex flex-col sm:flex-row gap-x-5 mt-8 sm:mt-0 gap-y-8 sm:gap-y-0 text-center sm:text-left">
              <div className="flex-1 flex flex-col gap-y-2.5">
                <p className="text-wilderness text-body16 font-bold mb-4 sm:mb-2.5">
                  Explore
                </p>
                {explore.map(({ label, route }) => {
                  return <FooterLink key={label} label={label} route={route} />;
                })}
              </div>
              <div className="flex-1 flex flex-col gap-y-2.5">
                <p className="text-ocean-rescue text-body16 font-bold mb-2.5">
                  Learn
                </p>
                {learn.map(({ label, route }) => {
                  return <FooterLink key={label} label={label} route={route} />;
                })}
              </div>
              <div className="flex-1 flex flex-col gap-y-2.5">
                <p className="text-clean-tech text-body16 font-bold mb-2.5">
                  Support
                </p>
                {support.map(({ label, route }) => {
                  return <FooterLink key={label} label={label} route={route} />;
                })}
              </div>
            </div>
          </div>
          <hr className="border border-black" />
          <form
            className="flex flex-col sm:flex-row items-center"
            onSubmit={submitEmail}
          >
            <label
              htmlFor="subscribe-input"
              className="flex-1 flex flex-col gap-y-2.5 text-white text-body16 leading-7 text-center sm:text-left mb-4 sm:mb-0"
            >
              <p className="font-bold">Subscribe to our newsletter</p>
              <p className="font-normal sm:mr-10">
                Inspiring content from the world&apos;s most effective
                sustainability projects.
              </p>
            </label>
            <div className="flex-1 relative w-full">
              <input
                id="subscribe-input"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                className={
                  `w-full focus:outline-none border-2 ` +
                  `border-field-grey/40 focus:border-field-grey/100 rounded-lg ` +
                  `bg-transparent p-4 pr-32 text-white text-body16 `
                }
                style={{
                  transition: "border 0.6s cubic-bezier(0.16, 1, 0.3, 1)",
                }}
                placeholder="example@email.com"
              />
              <button
                type="submit"
                className={
                  `absolute flex top-0 right-0 h-full focus:outline-none ` +
                  `sm:justify-end px-4 pr-5 items-center space-x-2`
                }
              >
                <p className="text-body16 text-white hover:text-wilderness font-bold">
                  Subscribe
                </p>
                <div className="flex w-5">
                  <IconArrowRight color={Colors.wilderness} size="100%" />
                </div>
              </button>
              <div className="absolute">
                <div
                  className={`${
                    responseMessage.message ? "flex" : "hidden"
                  } pt-2 text-${responseMessage.color} `}
                >
                  {responseMessage.color === "error-red" ? (
                    <IconX />
                  ) : (
                    <IconCircleCheck color={Colors.successGreen} />
                  )}
                  &nbsp;{responseMessage.message}
                </div>
              </div>
            </div>
          </form>
          <hr className="border border-black" />
          <div className="flex flex-col sm:flex-row gap-y-5 sm:gap-y-0 sm:justify-between text-center">
            <div className="flex flex-col sm:flex-row gap-y-5 sm:gap-y-0 sm:gap-x-24 text-white text-body16 leading-7 font-medium">
              <Link href="/terms">Terms & Conditions</Link>
              <Link href="/privacy">Privacy Policy</Link>
            </div>
            <p className="text-white text-body16 leading-7 font-normal">
              © {new Date().getFullYear()} All rights reserved.
            </p>
          </div>
        </footer>
      </Section>
    </div>
  );
};

export default ExpandedFooter;
