import { OrganisationType } from "types/organisations";

const HOME = { label: "Home", route: "/" };
const MAIN_SITE = { label: "Main Site", route: "/" };
const STORIES = { label: "Stories", route: "/stories" };
const PROJECTS = { label: "Projects", route: "/projects" };
const PROJECT_SELECTION = {
  label: "Project Selection",
  route: "/project-selection",
};
const ABOUT_US = { label: "About Us", route: "/about-us" };
const PERSONAL_ACCOUNTS = {
  label: "Personal Pinwheel",
  route: "/create-personal-account",
};
const ACCOUNT = { label: "Home", route: "/account" };
const ACTION_FEED = { label: "Action Feed", route: "/account/action-feed" };
const PROFILE = { label: "Profile", route: "/account/profile#home" };
const PREFERENCES = { label: "Preferences", route: "/preferences" };
const LOGOUT = { label: "Logout", route: "/logout" };
const LOGIN = { label: "Login", route: "/login" };
// TODO: We need a dedicated support form for registered users.
const SUPPORT = { label: "Support", route: "/contact" };
const CONTACT = { label: "Contact", route: "/contact" };
const FAQS = { label: "FAQs", route: "/faqs" };
const MEDIA = { label: "Media", route: "/media" };
const CAREERS = { label: "Careers", route: "/team#careers" };
const CONTRIBUTION_MARK = {
  label: "Contribution Mark",
  route: "/contribution-mark",
};
const SECURITY = { label: "Security at Pinwheel", route: "/security" };
const TEAM = { label: "Team", route: "/team" };

export type NavigationLink = { label: string; route: string };

type SocialLinksType = { twitter: string; instagram: string; linkedIn: string };

type NavigationLinks = {
  primary: NavigationLink[]; // First column on desktop
  secondary1: NavigationLink[]; // Second column on desktop
  secondary2: NavigationLink[]; // Third column on desktop
  avatar: string; // Circular avatar
  button: NavigationLink; // Button in bottom of nav overlay
  social: SocialLinksType; // Links to our social channels
};

export const getNavigationLinks = (
  isAuthenticated: boolean,
  organisations: OrganisationType[]
): NavigationLinks => ({
  primary: getPrimaryLinks(isAuthenticated),
  secondary1: getSecondary1Links(isAuthenticated),
  secondary2: getSecondary2Links(organisations),
  avatar: getAvatarLink(isAuthenticated),
  button: getButtonLink(isAuthenticated),
  social: socialLinks,
});

const getOrganisationLink = (
  organisation: OrganisationType
): NavigationLink => ({
  label: `${organisation.name}'s Impact`,
  route: `/organisations/${organisation.slug}`,
});

const getPrimaryLinks = (isAuthenticated): NavigationLink[] =>
  isAuthenticated
    ? [ACCOUNT, PROFILE, ACTION_FEED, STORIES, PROJECTS]
    : [HOME, STORIES, PROJECTS, PROJECT_SELECTION, PERSONAL_ACCOUNTS];

const getSecondary1Links = (isAuthenticated): NavigationLink[] =>
  isAuthenticated
    ? [PREFERENCES, SUPPORT, MAIN_SITE, FAQS, TEAM, ABOUT_US]
    : [FAQS, ABOUT_US, CONTRIBUTION_MARK, CONTACT, MEDIA, TEAM, CAREERS];

const getSecondary2Links = (
  organisations: OrganisationType[] = []
): NavigationLink[] => [
  ...organisations.map((organisation) => getOrganisationLink(organisation)),
  SECURITY,
  CONTRIBUTION_MARK,
  PROJECT_SELECTION,
];

const getAvatarLink = (isAuthenticated): string =>
  isAuthenticated ? "/account" : "/login";

const getButtonLink = (isAuthenticated): NavigationLink =>
  isAuthenticated ? LOGOUT : LOGIN;

export const socialLinks = {
  instagram: "https://www.instagram.com/pinwheel_earth",
  twitter: "https://www.twitter.com/pinwheel_earth",
  linkedIn: "https://www.linkedin.com/company/pinwheelworld",
};

type FooterLinksType = {
  explore: NavigationLink[];
  learn: NavigationLink[];
  support: NavigationLink[];
  social: SocialLinksType;
};

const getExploreLinks = (isAuthenticated): NavigationLink[] =>
  isAuthenticated
    ? [ACCOUNT, PROFILE, ACTION_FEED, PREFERENCES, MAIN_SITE, STORIES, PROJECTS]
    : [STORIES, PROJECTS, PERSONAL_ACCOUNTS];

const learnLinks = [
  FAQS,
  PROJECT_SELECTION,
  ABOUT_US,
  TEAM,
  CONTRIBUTION_MARK,
  SECURITY,
  CAREERS,
];

const supportLinks = [SUPPORT, CONTACT, MEDIA, CAREERS];

export const getFooterLinks = (isAuthenticated: boolean): FooterLinksType => ({
  explore: getExploreLinks(isAuthenticated),
  learn: learnLinks,
  support: supportLinks,
  social: socialLinks,
});
