import Link from "next/link";

import { useUserContext } from "contexts/UserContext";

import Section from "components/containers/Section";
import PinwheelPrimaryLogo from "components/logo/PinwheelPrimaryLogo";

const ShortFooter = ({ bgColor }) => {
  const { isAuthenticated } = useUserContext();
  return (
    <div
      className={`${bgColor} py-10 px-4`}
      style={{ transition: "background 1s cubic-bezier(0.16, 1, 0.3, 1)" }}
    >
      <Section className="flex flex-col sm:flex-row space-y-10 sm:space-y-0 justify-between items-center">
        <Link href="/">
          <a>
            <div className="cursor-pointer max-w-[60px] mx-auto sm:mx-0">
              <PinwheelPrimaryLogo />
            </div>
          </a>
        </Link>
        {isAuthenticated ? (
          <div className="flex flex-col sm:flex-row justify-between items-center sm:space-x-12 space-y-8 sm:space-y-0 text-white text-body16">
            <Link href="/account">
              <a>Home</a>
            </Link>
            <Link href="/account/profile">
              <a>Profile</a>
            </Link>
            <Link href="/account/action-feed">
              <a>Action Feed</a>
            </Link>
            <p className="text-silver font-light">
              © {new Date().getFullYear()} All rights reserved.
            </p>
          </div>
        ) : (
          <div className="flex flex-col sm:flex-row justify-between items-center sm:space-x-12 space-y-8 sm:space-y-0 text-white text-body16">
            <Link href="/terms">
              <a>Terms & Conditions</a>
            </Link>
            <Link href="/privacy">
              <a>Privacy Policy</a>
            </Link>
            <p>© {new Date().getFullYear()} All rights reserved.</p>
          </div>
        )}
      </Section>
    </div>
  );
};

export default ShortFooter;
