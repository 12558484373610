/*
I'm deliberately co-locating the types and context below because it's all
going to get depreciated and I feel it's easier to work with this as a single
file.

The plans data structure is in maintenance mode and is going to be
replaced by plan builder when we get around to building it.
 */
import { ReactNode, createContext, useContext, useState } from "react";

import { Theme } from "types/ui";

type OverviewType = {
  current_plan: boolean;
  button_text: string;
};

type LevelType = {
  price: string;
  plan_features: string[];
  card_text: string;
  current_plan: boolean;
};

type LevelGroupType = {
  essential: LevelType;
  extra: LevelType;
  epic: LevelType;
};

type PlanBreakdownType = {
  name: string;
  slug: Theme;
  overview: OverviewType;
  levels: LevelGroupType;
};

export type PlanGroupType = {
  type: string;
  country: string;
  currency_alpha_3: string;
  currency_symbol: string;
  default_theme: Theme;
  default_level: string;
  default_price: string;
  plan_status: string;
  plans: PlanBreakdownType[];
};

type NewUserType = {
  email: string;
  account_type: string;
  first_name: string;
  last_name: string;
  email_updates: boolean;
};

type SelectedPlanType = {
  country: string;
  theme: Theme;
  level: string;
  plan_type: string;
  billing_amount: string;
  billing_currency: string;
  flow: string;
};

type SubscriptionResponseType = {
  theme: Theme;
  client_secret_type: string;
  client_secret: string;
  first_name: string;
  last_name: string;
  backRoute: string;
  backButtonText: string;
  successRoute: string;
};

type ForgotPasswordType = {
  email: string;
  login_secret: string;
};

type AccountContextType = {
  planGroup?: PlanGroupType;
  newUser?: NewUserType;
  selectedPlan?: SelectedPlanType;
  subscriptionResponse?: SubscriptionResponseType;
  forgotPassword: ForgotPasswordType;
  setPlanGroup: (PlanGroupType) => void;
  setNewUser: (NewUserType) => void;
  setSelectedPlan: (SelectedPlanType) => void;
  setSubscriptionResponse: (SubscriptionResponseType) => void;
  setForgotPassword: (ForgotPasswordType) => void;
};

type ContextProps = {
  children: ReactNode;
};

const initialPlanGroup = {
  type: "personal",
  country: "GB",
  currency_alpha_3: "GBP",
  currency_symbol: "£",
  default_theme: Theme.wilderness,
  default_level: "essential",
  default_price: "0.00",
  plan_status: "none",
  plans: [
    {
      name: "wilderness",
      slug: Theme.wilderness,
      overview: {
        current_plan: false,
        button_text: "Choose",
      },
      levels: {
        essential: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
        extra: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
        epic: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
      },
    },
    {
      name: "ocean rescue",
      slug: Theme.oceanRescue,
      overview: {
        current_plan: false,
        button_text: "Choose",
      },
      levels: {
        essential: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
        extra: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
        epic: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
      },
    },
    {
      name: "clean tech",
      slug: Theme.cleanTech,
      overview: {
        current_plan: false,
        button_text: "Choose",
      },
      levels: {
        essential: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
        extra: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
        epic: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
      },
    },
    {
      name: "new horizons",
      slug: Theme.newHorizons,
      overview: {
        current_plan: false,
        button_text: "Choose",
      },
      levels: {
        essential: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
        extra: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
        epic: {
          price: "",
          plan_features: [""],
          card_text: "",
          current_plan: false,
        },
      },
    },
  ],
};

const initialNewUser = {
  email: "",
  account_type: "personal",
  first_name: "",
  last_name: "",
  email_updates: false,
};

const initialSelectedPlan = {
  country: "GB",
  theme: Theme.wilderness,
  level: "essential",
  plan_type: "personal",
  billing_amount: "8.00",
  billing_currency: "GBP",
  flow: "create-personal-account",
};

const initialSubscriptionResponse = {
  theme: Theme.wilderness,
  client_secret_type: "",
  client_secret: "",
  first_name: "",
  last_name: "",
  backRoute: "",
  backButtonText: "",
  successRoute: "",
};

const initialForgotPassword = {
  email: "",
  login_secret: "",
};

const initialAccountContextData = {
  planGroup: initialPlanGroup,
  newUser: initialNewUser,
  selectedPlan: initialSelectedPlan,
  subscriptionResponse: initialSubscriptionResponse,
  forgotPassword: initialForgotPassword,
  setPlanGroup: () => {},
  setNewUser: () => {},
  setSelectedPlan: () => {},
  setSubscriptionResponse: () => {},
  setForgotPassword: () => {},
};

export const AccountContext = createContext<AccountContextType>(
  initialAccountContextData
);

export const AccountContextProvider = ({ children }: ContextProps) => {
  const [planGroup, setPlanGroup] = useState(initialPlanGroup);
  const [newUser, setNewUser] = useState(initialNewUser);
  const [selectedPlan, setSelectedPlan] = useState(initialSelectedPlan);
  const [subscriptionResponse, setSubscriptionResponse] = useState(
    initialSubscriptionResponse
  );
  const [forgotPassword, setForgotPassword] = useState(initialForgotPassword);

  return (
    <AccountContext.Provider
      value={{
        planGroup,
        newUser,
        selectedPlan,
        subscriptionResponse,
        forgotPassword,
        setPlanGroup,
        setNewUser,
        setSelectedPlan,
        setSubscriptionResponse,
        setForgotPassword,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccountContext = () => {
  const context = useContext(AccountContext);

  if (context === undefined) {
    throw new Error("useAccountContext must be used within the Provider");
  }

  return context;
};
