import { useContext } from "react";

import Script from "next/script";

import { CookiesPreferencesContext } from "contexts/CookiesPreferencesContext";

import * as gtag from "helpers/utils/gtag";

const GTag = () => {
  const { cookiesPreferences } = useContext(CookiesPreferencesContext);

  return cookiesPreferences.analytics && gtag.GA_TRACKING_ID ? (
    <>
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
    </>
  ) : (
    <></>
  );
};

export default GTag;
