import { ReactNode, createContext, useEffect, useState } from "react";

import { checkPreferenceCookieValues } from "helpers/utils/cookies";

const CookiesPreferencesContext = createContext({
  cookiesPreferences: {
    essential: true,
    performance: false,
    analytics: false,
    social: false,
  },
  setCookiesPreferences: (_) => {},
});

type Props = {
  children: ReactNode;
};

const CookiesPreferencesContextProvider = ({ children }: Props) => {
  const [cookiesPreferences, setCookiesPreferences] = useState({
    essential: true,
    performance: false,
    analytics: false,
    social: false,
  });

  useEffect(
    () =>
      setCookiesPreferences({
        essential: checkPreferenceCookieValues("essential"),
        performance: checkPreferenceCookieValues("performance"),
        analytics: checkPreferenceCookieValues("analytics"),
        social: checkPreferenceCookieValues("social"),
      }),
    []
  );

  return (
    <CookiesPreferencesContext.Provider
      value={{ cookiesPreferences, setCookiesPreferences }}
    >
      {children}
    </CookiesPreferencesContext.Provider>
  );
};

export { CookiesPreferencesContext, CookiesPreferencesContextProvider };
