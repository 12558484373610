import { ReactNode } from "react";

import Image from "next/image";

import { Theme } from "types/ui";

import CleanTechAvatar from "public/img/avatars/flat/clean_tech.svg";
import NewHorizonsAvatar from "public/img/avatars/flat/new_horizons.svg";
import OceanRescueAvatar from "public/img/avatars/flat/ocean_rescue.svg";
import WildernessAvatar from "public/img/avatars/flat/wilderness.svg";

type AvatarProps = {
  avatarUrl?: string;
  theme: Theme;
};

const getAvatarByTheme = (theme: Theme): ReactNode => {
  let src = WildernessAvatar;
  if (theme === Theme.wilderness) {
    src = WildernessAvatar;
  } else if (theme === Theme.oceanRescue) {
    src = OceanRescueAvatar;
  } else if (theme === Theme.cleanTech) {
    src = CleanTechAvatar;
  } else if (theme === Theme.newHorizons) {
    src = NewHorizonsAvatar;
  }

  return <Image src={src} alt="Your profile avatar" />;
};

const Avatar = ({ avatarUrl, theme }: AvatarProps) => {
  let avatar;
  if (avatarUrl) {
    avatar = <Image src={avatarUrl} alt="Your profile picture" layout="fill" />;
  } else {
    avatar = getAvatarByTheme(theme);
  }

  return (
    <div className="relative w-full aspect-square rounded-full overflow-hidden select-none">
      {avatar}
    </div>
  );
};

export default Avatar;
