import { useEffect, useState } from "react";

import {
  useRefRevealContentGrowth,
  useRefRevealOpacity,
} from "helpers/utils/uiReactRef";
import { getTransitions } from "helpers/utils/uiSetup";

export enum RevealModes {
  height = "height",
  opacity = "opacity",
}

type RevealProps = {
  isActive?: boolean;
  children: React.ReactNode;
  mode?: RevealModes;
};

const OpacityReveal = ({ children }: RevealProps) => {
  const [handler, refs] = useRefRevealOpacity();
  const [pageIsReady, setPageIsReady] = useState(false);

  useEffect(() => setPageIsReady(true), []);
  useEffect(() => {
    handler();
  }, [pageIsReady]);
  useEffect(() => {
    if (pageIsReady) handler();
  }, [pageIsReady, handler]);

  return (
    <div
      ref={refs.main}
      className={`opacity-0`}
      style={{
        transition: getTransitions(["opacity"]),
      }}
    >
      {children}
    </div>
  );
};

const HeightReveal = ({ isActive = true, children }: RevealProps) => {
  const [handler, refs] = useRefRevealContentGrowth();
  const [pageIsReady, setPageIsReady] = useState(false);

  useEffect(() => setPageIsReady(true), []);
  useEffect(() => {
    handler(isActive);
  }, [pageIsReady]);
  useEffect(() => {
    if (pageIsReady) handler(isActive);
  }, [isActive, pageIsReady, handler]);

  return (
    <div
      ref={refs.main}
      className={`overflow-hidden h-0 opacity-0`}
      style={{
        transition: getTransitions(["height", "margin", "opacity"]),
      }}
    >
      <div className="flex-1" ref={refs.content}>
        {children}
      </div>
    </div>
  );
};

const Reveal = ({
  isActive = true,
  children,
  mode = RevealModes.height,
}: RevealProps) => {
  if (mode === RevealModes.height) {
    return <HeightReveal isActive={isActive}>{children}</HeightReveal>;
  }

  if (mode === RevealModes.opacity) {
    return <OpacityReveal>{children}</OpacityReveal>;
  }
};

export default Reveal;
