const initialState = {
  // Network error popup state
  hasServerError: false,
  serverErrorCode: "",
  message: "We've ran into an issue",
  override: false,

  // Forgot password screen state
  forgotPasswordProgress: "enter_email",
  forgotPasswordData: {},
};

export default initialState;
