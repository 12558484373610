import Image, { ImageLoader, ImageLoaderProps, ImageProps } from "next/image";

import { Asset } from "contentful";

import { getBlurDataURL } from "helpers/utils/images";

interface ContentfulImageProps extends Omit<ImageProps, "src"> {
  className?: string;
  image: Asset;
  width?: number;
  height?: number;
}

interface ContentfulLoaderProps extends ImageLoaderProps {
  height?: number;
}

const contentfulLoader: ImageLoader = ({
  src,
  width,
  height,
  quality,
}: ContentfulLoaderProps) => {
  const url = new URL(`https:${src}`);
  if (width) {
    url.searchParams.append("w", `${width}`);
  }
  if (quality) {
    url.searchParams.append("q", `${quality}`);
  }
  if (height) {
    url.searchParams.append("h", `${height}`);
  }
  url.searchParams.append("fit", "fill");
  return url.href;
};

export const ContentfulImage = (props: ContentfulImageProps) => {
  const { image, ...imageProps } = props;

  const src = image.fields.file.url;
  const alt = image.fields.description;

  return (
    <Image
      src={src}
      alt={alt}
      loader={contentfulLoader}
      placeholder="blur"
      blurDataURL={getBlurDataURL(`https:${src}`, 20)}
      {...imageProps}
    />
  );
};
