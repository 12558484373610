import { useRouter } from "next/router";

import { useUIContext } from "contexts/UIContext";

export const useLoadingEffectRouter = () => {
  const router = useRouter();
  const { setIsLoading } = useUIContext();
  return async (path) => {
    setIsLoading(true);
    await router.push(path);
    setIsLoading(false);
  };
};

export const useRefreshPageRouter = () => {
  const router = useRouter();
  const { setIsLoading } = useUIContext();
  return async () => {
    setIsLoading(true);
    await router.push(router.pathname);
    setIsLoading(false);
  };
};
