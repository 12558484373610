import { Theme } from "types/ui";

import { getCookie, pickCookieFromJar, setCookie } from "helpers/utils/cookies";

export const getThemeCookie = (headers = ""): Theme => {
  let theme;
  if (typeof document === "undefined") {
    try {
      theme = pickCookieFromJar("userGroup", headers);
    } catch (error) {
      theme = Theme.wilderness;
    }
  } else {
    theme = getCookie("userGroup");
  }
  if (!theme) {
    theme = Theme.wilderness;
  }
  return theme;
};

export const setThemeCookie = (theme: Theme) => {
  setCookie("userGroup", theme, 365);
  return true;
};

export const sortUiPrice = (price) =>
  price % 1 != 0 ? price : Math.round(price);

export const hyphenToCamelCase = (value: string) =>
  value.replace(/-([a-z])/g, (g) => g[1].toUpperCase());

export const hyphenToUnderscore = (value: string) => value.replace(/-/g, "_");

export const timeout = async (delay) => {
  return new Promise((res) => setTimeout(res, delay));
};

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  delay: number = 500
): ((...args: Parameters<F>) => void) => {
  let timer;

  return (...args) => {
    clearTimeout(timer);

    timer = setTimeout(() => {
      func(...args);
    }, delay);
  };
};
