import { RefObject, useRef } from "react";

export const useRefRevealContentGrowth = (): [
  (isActive: boolean) => void,
  { main: RefObject<HTMLDivElement>; content: RefObject<HTMLDivElement> }
] => {
  const mainRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const handler = (isActive) => {
    mainRef.current.style.height = isActive
      ? contentRef.current.offsetHeight + "px"
      : null;
    /* Sort margins animation. */
    const styles = getComputedStyle(mainRef.current);
    // TODO - ensure cross-browser
    // mainRef.current.style.marginTop = isActive ? styles.marginTop: "0px";
    mainRef.current.style.marginBottom = isActive
      ? "0px"
      : "-" + styles.marginTop;
    mainRef.current.style.opacity = isActive ? "1" : "0";
    new ResizeObserver(() => {
      if (mainRef.current) {
        mainRef.current.style.height = isActive
          ? contentRef.current.offsetHeight + "px"
          : null;
      }
    }).observe(contentRef.current);
  };
  return [handler, { main: mainRef, content: contentRef }];
};

export const useRefRevealOpacity = (): [
  () => void,
  { main: RefObject<HTMLDivElement> }
] => {
  const mainRef = useRef<HTMLDivElement>(null);
  const handler = () => {
    setTimeout(() => {
      mainRef.current.style.opacity = "1";
    }, 1);
  };

  return [handler, { main: mainRef }];
};
