import { Colors } from "helpers/utils/uiSetup";

type MenuIconProps = {
  color: string;
};
const MenuIcon = ({ color = Colors.white }: MenuIconProps) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.9411 10.2522H34.9999C35.4419 10.2522 35.8658 10.0766 36.1784 9.764C36.4909 9.45144 36.6665 9.02752 36.6665 8.58549C36.6665 8.14346 36.4909 7.71954 36.1784 7.40698C35.8658 7.09442 35.4419 6.91882 34.9999 6.91882H17.9411C17.499 6.91882 17.0751 7.09442 16.7625 7.40698C16.45 7.71954 16.2744 8.14346 16.2744 8.58549C16.2744 9.02752 16.45 9.45144 16.7625 9.764C17.0751 10.0766 17.499 10.2522 17.9411 10.2522ZM34.9999 18.3334H3.8234C3.38138 18.3334 2.95745 18.509 2.64489 18.8215C2.33233 19.1341 2.15674 19.558 2.15674 20.0001C2.15674 20.4421 2.33233 20.866 2.64489 21.1786C2.95745 21.4911 3.38138 21.6667 3.8234 21.6667H34.9999C35.4419 21.6667 35.8658 21.4911 36.1784 21.1786C36.4909 20.866 36.6665 20.4421 36.6665 20.0001C36.6665 19.558 36.4909 19.1341 36.1784 18.8215C35.8658 18.509 35.4419 18.3334 34.9999 18.3334Z"
        fill={color}
      />
      <path
        d="M12.0587 29.748H34.9999C35.4419 29.748 35.8658 29.9235 36.1784 30.2361C36.4909 30.5487 36.6665 30.9726 36.6665 31.4146C36.6665 31.8566 36.4909 32.2806 36.1784 32.5931C35.8658 32.9057 35.4419 33.0813 34.9999 33.0813H12.0587C11.6167 33.0813 11.1927 32.9057 10.8802 32.5931C10.5676 32.2806 10.392 31.8566 10.392 31.4146C10.392 30.9726 10.5676 30.5487 10.8802 30.2361C11.1927 29.9235 11.6167 29.748 12.0587 29.748Z"
        fill={color}
      />
    </svg>
  );
};

export default MenuIcon;
