export class PayloadForComponent {
  success;
  response;

  constructor() {
    this.success = false;
    this.response = {};
  }

  as = (success, data) => {
    this.success = success;
    if (data) this.response = data;
  };
}
