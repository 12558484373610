export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GTAG_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  if (window.gtag) {
    window.gtag("config", GA_TRACKING_ID, {
      page_path: url,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = (
  action: string,
  properties?: { category?: string; label?: string; value?: string }
) => {
  if (window.gtag) {
    window.gtag("event", action, {
      event_category: properties?.category,
      event_label: properties?.label,
      value: properties?.value,
    });
  }
};
