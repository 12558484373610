import { GetServerSidePropsContext } from "next";
import Router from "next/router";

import axios, { AxiosInstance } from "axios";

import { assertHasServerError } from "store/actions";

import { pickCookieFromJar, sortNewCookiesJar } from "helpers/utils/cookies";
import {
  AuthenticationError,
  BackEndError,
  CsrfTokenError,
  NetworkError,
  NotFoundError,
  WrongRequestError,
  WrongResponseError,
} from "helpers/utils/customErrors";

// We explicitly export two different versions of getApiFetcher for server-side and client-side.
// This is done so that engineers are forced to choose which type of request they want which
// reduces the risk of misusing the API fetcher (for example, not including req for a server-side
// request which then tries to access document.cookies)
export const getServerSideApiFetcher = (
  req: GetServerSidePropsContext["req"]
): AxiosInstance => getApiFetcher({ req });

export const getClientSideApiFetcher = (): AxiosInstance => getApiFetcher();

const getApiFetcher = (
  options?: Partial<{
    req: GetServerSidePropsContext["req"];
  }>
) => {
  const { req } = options || {};

  const axiosInstance = axios.create({
    timeout: 30000,
    withCredentials: true,
    baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
    },
    // Use axios's build in XSRF feature to prevent the tokens getting out of sync if the csrftoken
    // cookie changes after the axios instance is created but before the request is made
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken",
  });

  if (req) {
    // The server-side NodeJS environment doesn't automatically have access to cookies
    // so we set these explicitly here.
    axiosInstance.defaults.headers.common["Cookie"] = sortNewCookiesJar(
      ["sessionid", "csrftoken"],
      req.headers.cookie
    );
    axiosInstance.defaults.headers.common["X-CSRFToken"] = pickCookieFromJar(
      "csrftoken",
      req.headers.cookie
    );
  }

  axiosInstance.interceptors.response.use(
    undefined,
    !req ? clientSideErrorInterceptor : serverSideErrorInterceptor
  );

  return axiosInstance;
};

const clientSideErrorInterceptor = (error) => {
  try {
    if (error.message === "Network Error") {
      assertHasServerError(
        true,
        "Network Error",
        "Unable to complete this request"
      );
      return Promise.reject(new NetworkError());
    }

    if (error.code === "ERR_HTTP_INVALID_HEADER_VALUE")
      return Promise.reject(new WrongRequestError());

    if (error.response && error.response.status) {
      const { status } = error.response;
      let message = "We've ran into a problem with this request";
      try {
        message = error.response.data.message;
      } catch (error) {}
      if ([500, 408, 502].includes(status)) {
        assertHasServerError(true, status, message);
      } else if (status === 403) {
        const { error_type } = error.response.data;
        if (error_type === "authentication_error") {
          Router.push("/login");
        } else if (error_type === "csrf_error") {
          // TODO - Evaluate - Redirect to current location
          Router.push("/");
        }
      }
    } else {
      return Promise.reject(new WrongResponseError());
    }
  } catch (tryError) {
    return Promise.reject(tryError);
  }
  return Promise.reject(error);
};

const serverSideErrorInterceptor = (error) => {
  try {
    if (error.code === "ERR_HTTP_INVALID_HEADER_VALUE")
      return Promise.reject(new WrongRequestError());

    if (error.message === "Network Error")
      return Promise.reject(new NetworkError());

    if (error.response && error.response) {
      const { status } = error.response;
      if ([500, 408, 502].includes(status)) {
        return Promise.reject(new BackEndError());
      } else if (status === 403) {
        const { error_type } = error.response.data;
        if (error_type === "authentication_error") {
          return Promise.reject(new AuthenticationError());
        } else if (error_type === "csrf_error") {
          return Promise.reject(new CsrfTokenError());
        }
      } else if (status === 404) {
        return Promise.reject(new NotFoundError());
      }
    }
  } catch (tryError) {
    return Promise.reject(tryError);
  }
  return Promise.reject(error);
};

export const fetchPropsOrLogOut = async (entries, req, props = {}) => {
  let result = { props: { ...props } };
  const apiFetcher = getServerSideApiFetcher(req);
  const ssRedirects = {
    homepage: { redirect: { destination: "/", permanent: false } },
    login: { redirect: { destination: "/login", permanent: false } },
    notFound: { notFound: true } as const,
  };

  for await (const { endpoint, propName } of entries) {
    try {
      const { data } = await apiFetcher.get(endpoint);
      result.props[propName || endpoint] = data.body || data;
    } catch (error) {
      if (error instanceof AuthenticationError) return ssRedirects.login;
      else if (error instanceof CsrfTokenError) return ssRedirects.homepage;
      else if (error instanceof WrongRequestError) return ssRedirects.homepage;
      else if (error instanceof WrongResponseError) return ssRedirects.homepage;
      else if (error instanceof NetworkError) return ssRedirects.homepage;
      else if (error instanceof BackEndError) return ssRedirects.homepage;
      else if (error instanceof NotFoundError) return ssRedirects.notFound;
      else ssRedirects.homepage;
    }
  }
  return result;
};
