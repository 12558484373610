import { Fragment } from "react";
import { connect } from "react-redux";

import { IconX } from "@tabler/icons";

import { assertHasServerError } from "store/actions";

import { Colors, getTransitions } from "helpers/utils/uiSetup";

const ServerErrorBanner = ({ hasServerError, message, override = false }) => {
  if (!hasServerError) return <Fragment />;
  if (typeof message == "undefined") {
    message = "We've ran into an issue";
  }
  let finalMessage;
  if (!override) {
    finalMessage =
      `${message} - if you're encountering this message repeatedly it may be ` +
      `an internet connection or network problem and you should try again shortly.`;
  } else {
    finalMessage = message;
  }

  const closePopup = () =>
    assertHasServerError(false, 500, "We've ran into an issue");

  return (
    <div
      className={
        `fixed bottom-10 left-10 right-10 w-auto pointer-events-none ` +
        ` text-center ${hasServerError ? "opacity-100" : "opacity-0"}`
      }
      style={{ transition: getTransitions(["opacity"]) }}
    >
      <div
        className={
          `relative inline-block border-warning-orange border ` +
          `bg-warning-orange-light rounded-lg  pointer-events-auto ` +
          `mx-auto p-4 pt-5 px-5`
        }
      >
        <div className="content flex justify-between items-center">
          <p className="text-left text-body14">
            {finalMessage}
            <a className="font-semibold" href="mailto:support@pinwheel.earth">
              {" "}
              Not Fixed? Contact support
            </a>
          </p>
          <div
            className="cursor-pointer h-4 w-4 flex ml-4"
            onClick={closePopup}
          >
            <IconX color={Colors.metalGrey} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  hasServerError: state.hasServerError,
  serverErrorCode: state.serverErrorCode,
  override: state.override,
  message: state.message,
});
export default connect(mapStateToProps)(ServerErrorBanner);
