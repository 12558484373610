import { useUIContext } from "contexts/UIContext";

import ExpandedFooter from "components/footer/ExpandedFooter";
import ShortFooter from "components/footer/shortFooter";

const Footer = () => {
  const { footerBackgroundColor, showShortFooter } = useUIContext();
  const footerBackgroundColorStyle = `bg-${footerBackgroundColor}`;
  return (
    <div>
      {showShortFooter ? (
        <ShortFooter bgColor={footerBackgroundColorStyle}></ShortFooter>
      ) : (
        <ExpandedFooter
          backgroundColor={footerBackgroundColorStyle}
        ></ExpandedFooter>
      )}
    </div>
  );
};

export default Footer;
