import { ReactNode, createContext, useContext, useState } from "react";

import { OrganisationType, OrganisationsResponse } from "types/organisations";
import { UserType } from "types/user";

type UserContextType =
  | {
      isAuthenticated: boolean;
      setIsAuthenticated: (isAuthenticated: boolean) => void;
      organisations: OrganisationType[];
      mainOrganisation?: OrganisationType;
      setUserResponse: (response: UserResponseType) => void;
      user: UserType;
      setUser: (user: UserType) => void;
      clearUserContext: () => void;
    }
  | undefined;

type UserResponseType = {
  isAuthenticated: boolean;
  organisations: OrganisationsResponse;
};

const UserContext = createContext<UserContextType>(undefined);

type ContextProviderProps = {
  initialUser: UserType | null;
  children: ReactNode;
};

export const UserContextProvider = ({
  initialUser,
  children,
}: ContextProviderProps) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    !!initialUser
  );
  const [organisations, setOrganisations] = useState<OrganisationType[]>(
    initialUser?.organisations || []
  );
  const [user, setUser] = useState<UserType>(initialUser);

  const setUserResponse = ({ isAuthenticated, organisations }) => {
    setIsAuthenticated(isAuthenticated);
    setOrganisations(organisations);
  };
  const clearUserContext = () => {
    setUser(null);
    setOrganisations([]);
    setIsAuthenticated(false);
  };
  const mainOrganisation = initialUser?.organisations?.[0];

  return (
    <UserContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        organisations,
        mainOrganisation,
        setUserResponse,
        user,
        setUser,
        clearUserContext,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error("useUserContext must be used within a UserContextProvider");
  }

  return context;
};
