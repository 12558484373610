import { Theme } from "types/ui";

/*
Note that when error handling is generating redirects here it's designed to handle issues
with SSR occasionally running without props data being available.

This isn't the cleanest way to do this because it generates a surprise behaviour, but
it does avoid the user hitting a 500 error within the application state
*/

export const convertHyphenToUnderScore = (string) => {
  return string?.replace(/-/g, "_");
};

export const convertUnderScoreToHyphen = (string) => {
  return string?.toLowerCase().replace(/_/g, "-");
};

export const convertUnderScoreToSpace = (string) => {
  return string?.replace(/_/g, " ");
};

export const convertSpaceToHyphen = (string) => {
  return string?.trim().toLowerCase().split(" ").join("-");
};

export const convertStringToTitleCase = (string) => {
  return string?.toLowerCase().replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const convertSlugToTitleCase = (slug) => {
  if (!slug) return null;
  const stringWithSpace = slug?.replace(/_/g, " ");
  return stringWithSpace
    .toLowerCase()
    .replace(/\b(\w)/g, (s) => s.toUpperCase());
};

export const getCategoryColor = (category: string) => {
  const categoryIndex = {
    Wilderness: "Wilderness",
    "Ocean Rescue": "Ocean Rescue",
    "Clean Tech": "Clean Tech",
    "New Horizons": "New Horizons",
    Biodiversity: "Wilderness",
    Pinwheel: "New Horizons",
    Carbon: "Ocean Rescue",
    COP26: "Ocean Rescue",
    Waste: "New Horizons",
    Food: "Wilderness",
    Lifestyle: "Wilderness",
    Shopping: "Ocean Rescue",
    Interviews: "New Horizons",
    Work: "Clean Tech",
  };
  return convertSpaceToHyphen(categoryIndex[category]);
};

export const roundWholeNumberPricing = (price) =>
  price % 1 != 0 ? price : Math.round(price);

export const trimForwardSlashPrefix = (value) => {
  let sortedValue = value.trim();
  if (sortedValue.charAt(0) === "/") {
    sortedValue = sortedValue.substring(1);
  }
  return sortedValue;
};

const THEME_FORMAT_MAP: {
  [key in Theme]: {
    hexcode: string;
    darkHexCode: string;
    darkColor: string;
    footerColor: string;
    cardBackground: string;
    cardImage: string;
  };
} = {
  [Theme.wilderness]: {
    hexcode: "#17FF60",
    darkHexCode: "#0B1221",
    darkColor: "navy",
    footerColor: "navy",
    cardBackground: "green-gradient-radial",
    cardImage: "offset_wilderness",
  },
  [Theme.oceanRescue]: {
    hexcode: "#00FFEF",
    darkHexCode: "#001533",
    darkColor: "deep-blue",
    footerColor: "deep-blue",
    cardBackground: "blue-gradient",
    cardImage: "offset_ocean_rescue",
  },
  [Theme.cleanTech]: {
    hexcode: "#FFA200",
    darkHexCode: "#1A0300",
    darkColor: "palm-brown",
    footerColor: "palm-brown",
    cardBackground: "yellow-gradient",
    cardImage: "offset_clean_tech",
  },
  [Theme.newHorizons]: {
    hexcode: "#FF69E7",
    darkHexCode: "#19013D",
    darkColor: "sunset-purple",
    footerColor: "sunset-purple",
    cardBackground: "purple-gradient",
    cardImage: "offset_new_horizons",
  },
};

export const getThemeFormatValue = (
  theme: Theme,
  value: keyof typeof THEME_FORMAT_MAP[Theme]
): string => {
  const formatForTheme = THEME_FORMAT_MAP[theme || Theme.wilderness];

  return formatForTheme[value];
};
