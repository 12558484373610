import Image from "next/image";
import Link from "next/link";

import {
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
  IconX,
} from "@tabler/icons";

import { useUIContext } from "contexts/UIContext";
import { useUserContext } from "contexts/UserContext";

import { getThemeFormatValue } from "helpers/utils/formatting";
import { convertUnderScoreToHyphen } from "helpers/utils/formatting";
import { useLoadingEffectRouter } from "helpers/utils/routing";

import Avatar from "components/images/Avatar";
import PinwheelPrimaryLogo from "components/logo/PinwheelPrimaryLogo";
import LargeLink from "components/navigation/navbar/elements/LargeLink";
import SmallLink from "components/navigation/navbar/elements/SmallLink";
import { getNavigationLinks } from "components/navigation/navbar/links";

import mountainsBackground from "public/img/backgrounds/layers/mountains_background.png";
import starsBackground from "public/img/backgrounds/layers/stars.svg";

type NavigationMenuProps = {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
};

const NavigationMenu = ({ isActive, setIsActive }: NavigationMenuProps) => {
  // Variables
  const { theme } = useUIContext();
  const { isAuthenticated, organisations } = useUserContext();

  const links = getNavigationLinks(isAuthenticated, organisations);

  // Router
  const router = useLoadingEffectRouter();

  const goToRoute = async (route) => {
    setIsActive(false);
    await router(route);
  };

  const click = async (e, route) => {
    e.preventDefault();
    await goToRoute(route);
  };

  return (
    <div
      className={`fixed right-0 z-30 ${isActive ? "w-full" : "w-0 delay-200"}`}
    >
      <div
        className={
          `flex flex-col right-0 bg-navy h-screen z-30 text-white ` +
          `overflow-auto w-full ${
            isActive ? "fade-in-point2s" : "fade-out-point2s"
          } `
        }
      >
        <div className="relative flex-none z-10 w-screen py-5 sm:py-10 ">
          <Image
            className={`z-0 pointer-events-none ${
              isActive ? "slide-in-down-1s" : ""
            }`}
            src={starsBackground}
            alt="Stars background image"
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
          <section className="relative mx-auto max-w-7xl px-5 sm:px-10 md:px-15">
            <div className="flex flex-row justify-between">
              <Link href={isAuthenticated ? "/account" : "/"}>
                <a
                  onClick={(e) => click(e, isAuthenticated ? "/account" : "/")}
                  className="w-11 sm:w-16"
                >
                  <div className="block sm:hidden">
                    <PinwheelPrimaryLogo />
                  </div>
                  <div className="hidden sm:block">
                    <PinwheelPrimaryLogo />
                  </div>
                </a>
              </Link>
              <div className="flex flex-row w-28 sm:w-40 justify-between">
                <a
                  href={links.avatar}
                  onClick={(e) => click(e, links.avatar)}
                  className="w-10 sm:w-16 block"
                >
                  <Avatar theme={theme} />
                </a>
                <div
                  className="cursor-pointer w-10"
                  onClick={() => setIsActive(false)}
                >
                  <IconX color="white" size="100%" />
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="flex-auto z-20 w-screen min-h-[520px]">
          <div
            className={
              `flex flex-col z-10 max-w-6xl mx-auto ` +
              `${isActive ? "fade-in-point2s-1s" : ""}`
            }
          >
            <div className="content-start hidden sm:block h-px bg-metal-grey opacity-40 mr-16 ml-5 sm:ml-16 xl:ml-5" />
            <div className="grid grid-cols-12">
              <div className="flex flex-col col-span-12 sm:col-span-5 gap-7 sm:gap-10 mt-10 ml-5 sm:ml-16 xl:ml-5">
                {links.primary.map((item, index) => (
                  <LargeLink
                    key={index}
                    label={item.label}
                    route={item.route}
                    goToRoute={goToRoute}
                  />
                ))}
                <div className="block sm:hidden h-px bg-metal-grey opacity-40 mt-3 sm:mt-0 mr-5" />
              </div>
              <div className="flex flex-col col-span-12 sm:col-span-7">
                <div className="grid grid-cols-12 mt-12 sm:mt-14 mr-16 ml-5 sm:ml-0">
                  <div className="flex flex-col col-span-12 sm:col-span-7 gap-8 mb-10">
                    {links.secondary1.map((item, index) => (
                      <SmallLink
                        key={index}
                        label={item.label}
                        route={item.route}
                        goToRoute={goToRoute}
                      />
                    ))}
                  </div>
                  <div className="flex flex-col col-span-12 sm:col-span-5 gap-8">
                    {links.secondary2.map((item, index) => (
                      <SmallLink
                        key={index}
                        label={item.label}
                        route={item.route}
                        goToRoute={goToRoute}
                      />
                    ))}
                  </div>
                  <div className="col-span-12 h-px bg-metal-grey opacity-40 mt-12 sm:mt-6" />
                  <div className="flex-auto col-span-12 mt-12">
                    <div className="grid grid-cols-12">
                      <div className="flex col-span-12 sm:col-span-6">
                        <a
                          href={links.button.route}
                          onClick={(e) => click(e, links.button.route)}
                        >
                          <button
                            className={
                              `border-${convertUnderScoreToHyphen(theme)} ` +
                              `border-2 justify-self-start self-end rounded-full ` +
                              `text-white font-normal h-[50px] w-44 `
                            }
                          >
                            {links.button.label}
                          </button>
                        </a>
                      </div>
                      <div className="flex col-span-12 sm:col-span-6 justify-start sm:justify-end">
                        <div className="flex items-center justify-center sm:justify-start space-x-6 md:space-x-10 my-12 sm:my-0">
                          <a
                            href={links.social.instagram}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconBrandInstagram
                              color={getThemeFormatValue(theme, "hexcode")}
                              stroke={1.5}
                            />
                          </a>
                          <a
                            href={links.social.twitter}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconBrandTwitter
                              color={getThemeFormatValue(theme, "hexcode")}
                              stroke={1.5}
                            />
                          </a>
                          <a
                            href={links.social.linkedIn}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <IconBrandLinkedin
                              color={getThemeFormatValue(theme, "hexcode")}
                              stroke={1.5}
                            />
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            `flex-1 z-10 w-screen xl:-mt-6 hidden sm:block ` +
            `bg-navy ${isActive ? "slide-in-up-1s" : ""}`
          }
        >
          <Image
            className="opacity-40 pointer-events-none"
            src={mountainsBackground}
            alt="Mountains background image"
            placeholder="blur"
          />
        </div>
        <div
          className={
            `flex-1 z-20 -mt-6 xl:mt-0 w-screen xl:-my-6 bg-muted-navy ` +
            `${isActive ? "slide-in-up-1s" : ""}`
          }
        />
      </div>
    </div>
  );
};

export default NavigationMenu;
