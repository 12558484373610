/**
 * @description High performant cookie value search on given string.
 * @param {string} name - Name of the target cookie.
 * @param {string} cookiesJar - String containing cookies.
 * @returns {string} - Returns target cookie value.
 */
export const pickCookieFromJar = (name, cookiesJar): string => {
  const sortedCookiesJar = `; ${cookiesJar}`;
  const parts = sortedCookiesJar.split(` ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
  return "";
};

/**
 * @description Gets cookie's value from DOM document
 * @param {any} name - Name of the target cookie.
 * @returns {any} - Returns target cookie value.
 */
export const getCookie = (name: string): string =>
  pickCookieFromJar(name, document.cookie);

/**
 * @description Sorts new cookies jar with given parameters
 * @param {Array} names - Cookies to be copied to new cookies jar
 * @param {string} cookiesJar - Cookies jar from where to copy cookies.
 * @returns {string} - Returns cookie jar with selected cookies.
 */
export const sortNewCookiesJar = (
  names: string[],
  cookiesJar: string
): string => {
  let newCookiesJar = "";
  names.forEach((name) => {
    let value = pickCookieFromJar(name, cookiesJar);
    if (value) newCookiesJar += ` ${name}=${value};`;
  });
  return newCookiesJar;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Max-Age=0";
};

export const setCookie = (name, value, days = 30, sameSite = "Lax") => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = "expires=" + date.toUTCString();
  document.cookie =
    name + "=" + value + ";" + expires + ";path=/" + ";SameSite=" + sameSite;
};

export const checkPreferenceCookieValues = (targetKey) => {
  const preferences = getCookie("preferenceSet");
  return (
    !!preferences &&
    preferences.split(" ").some((entry) => {
      const [key, value] = entry.split(":");
      return key === targetKey && value === "true";
    })
  );
};
