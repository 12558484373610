const PinwheelDarkLogo = ({ width = "100%", height = "100%" }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.1008 35.7065L64 43.6852L53.9841 28.4633C52.2299 25.7471 48.8347 24.6154 45.8355 25.634L35.6499 21.8992L43.5721 0L28.4067 10.0159C25.6905 11.8267 24.5588 15.1653 25.5774 18.221L21.8992 28.3501L0 20.3714L10.0159 35.5367C11.7701 38.2529 15.1088 39.328 18.1079 38.3661L28.3501 42.1008L20.4279 64L35.5933 53.9841C38.3095 52.2299 39.3846 48.8347 38.4226 45.8355L42.1008 35.7065ZM44.3077 26.2564L31.9717 32.0283L35.2538 23.0309L44.3077 26.2564ZM26.2564 19.7489L31.9717 32.0283L22.9744 28.7462L26.2564 19.7489ZM19.6357 37.7436L31.9717 31.9717L28.6897 41.0256L19.6357 37.7436ZM31.9717 32.0283L41.0256 35.3103L37.7436 44.3643L31.9717 32.0283Z"
        fill="#0B1221"
      />
    </svg>
  );
};

export default PinwheelDarkLogo;
