import { ButtonHTMLAttributes, useState } from "react";

import { Theme } from "types/ui";

import { convertUnderScoreToHyphen } from "helpers/utils/formatting";
import { Colors, getTransitions } from "helpers/utils/uiSetup";

import PinwheelLogo from "components/logo/pinwheelLogo";

const MIN_LOADING_TIME_MS = 250;

type ButtonProps = {
  text: string;
  onClick?: () => void;
  onMouseOver?: () => void;
  isActive?: boolean;
  isFullWidth?: boolean;
  withLoading?: boolean;
  borderColor?: string;
  textColor?: string;
  logoColor?: string;
  addClasses?: string;
  bgColor?: string;
  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
};

const Button = ({
  text,
  onClick = () => {},
  onMouseOver = () => {},
  isActive = true,
  isFullWidth = false,
  withLoading = true,
  borderColor = Theme.wilderness,
  textColor = "navy",
  logoColor = Colors.navy,
  addClasses = "",
  bgColor,
  type,
}: ButtonProps) => {
  const sortedBorderColor = convertUnderScoreToHyphen(borderColor);
  const [isLoading, setIsLoading] = useState(false);
  const onClickRunningHere = async () => {
    if (onClick && isActive && withLoading) {
      /* Defines flag to decide on showing loading or not. */
      let onClickIsRunning = true;
      /* Enables isLoading if isRunning is active after 25 milliseconds. */
      setTimeout(() => {
        if (onClickIsRunning) setIsLoading(true);
      }, 25);
      /* Runs onClick asynchronously. */
      await onClick();
      onClickIsRunning = false;
      /* Finally, disables the isLoading effect. */
      setTimeout(() => setIsLoading(false), MIN_LOADING_TIME_MS);
    }
  };
  return (
    <button
      onClick={onClickRunningHere}
      onMouseOver={onMouseOver}
      className={
        `relative font-medium focus:outline-none py-2.5 pb-2.5 px-6 ` +
        `rounded-full ` +
        `text-${textColor} text-body16 ${addClasses} ` +
        `${bgColor ? "bg-" + bgColor : ""} ` +
        `${
          isActive
            ? `border-${sortedBorderColor} border-2 text-opacity-100`
            : `border-metal-grey/10 border-2 bg-metal-grey/60 text-opacity-50`
        } ` +
        `${isFullWidth ? "w-full" : ""}`
      }
      style={{
        transition: getTransitions(["border", "background"]),
        minWidth: "180px",
      }}
      type={type}
    >
      {isLoading && (
        <div
          className="absolute top-0 right-0 h-full w-full pr-1.5 flex items-center justify-center"
          style={{ transition: getTransitions("opacity") }}
        >
          <div className="p-1 rounded-full  ">
            <div className="animate-spin h-7 w-7">
              <PinwheelLogo color={logoColor} />
            </div>
          </div>
        </div>
      )}
      <span
        className={`${isLoading ? "opacity-0 " : "opacity-100"}`}
        style={{ transition: getTransitions("opacity") }}
      >
        {text}
      </span>
    </button>
  );
};

export default Button;
