import * as React from "react";

type SectionProps = {
  className?: string;
  id?: string;
  children: React.ReactNode;
};

const Section = ({ className = "", children, ...otherProps }: SectionProps) => {
  return (
    <section
      {...otherProps}
      className={`mx-auto max-w-6xl px-5 sm:px-10 md:px-15 ${className}`}
    >
      {children}
    </section>
  );
};

export default Section;
