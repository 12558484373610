const toggleSwitch = ({ dark = false, value, onChange, disabled = false }) => {
  const selected = () => {
    onChange(!value);
  };
  return (
    <label className="switch">
      <input
        checked={disabled ? true : value}
        onChange={selected}
        type="checkbox"
      />
      <span className={`${dark ? "slider2" : "slider"} round`}></span>
    </label>
  );
};
export default toggleSwitch;
