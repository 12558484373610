import { Provider } from "react-redux";

import App from "next/app";

import { useStore } from "store";

import { UserType } from "types/user";

import { AccountContextProvider } from "contexts/AccountContext";
import { CookiesPreferencesContextProvider } from "contexts/CookiesPreferencesContext";
import { UIContextProvider } from "contexts/UIContext";
import { UserContextProvider } from "contexts/UserContext";

import { getServerSideApiFetcher } from "helpers/apis/apiFetcher";

import Layout from "components/layouts/Layout";
import GTag from "components/meta/GTag";

import "styles/globals.css";

function MyApp({ Component, pageProps, user }) {
  const store = useStore(pageProps.initialReduxState);

  return (
    <Provider store={store}>
      <UserContextProvider initialUser={user}>
        <UIContextProvider>
          <CookiesPreferencesContextProvider>
            <AccountContextProvider>
              <GTag />
              <Layout>
                <Component {...pageProps} />
              </Layout>
            </AccountContextProvider>
          </CookiesPreferencesContextProvider>
        </UIContextProvider>
      </UserContextProvider>
    </Provider>
  );
}

MyApp.getInitialProps = async (appContext) => {
  // Docs - Calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);
  const req = appContext.ctx.req;

  // Avoids issues with data failing to unload from the provider.
  let user: UserType | null = null;

  try {
    const response = await getServerSideApiFetcher(req).get(
      "/api/account/user/"
    );
    user = { ...response.data.body };
  } catch (error) {}

  return { ...appProps, user };
};

export default MyApp;
