import { useContext, useEffect, useState } from "react";

import { useRouter } from "next/router";

import { CookiesPreferencesContext } from "contexts/CookiesPreferencesContext";
import { useUIContext } from "contexts/UIContext";
import { useUserContext } from "contexts/UserContext";

import api from "helpers/apis/api";
import { getCookie, setCookie } from "helpers/utils/cookies";
import * as gtag from "helpers/utils/gtag";

import ServerErrorBanner from "components/banners/ServerErrorBanner";
import Footer from "components/footer/Footer";
import CookiePreference from "components/modals/cookiePreference";
import NavigationLoading from "components/navigation/NavigationLoading";
import Navbar from "components/navigation/navbar/Navbar";

const Layout = ({ children }) => {
  const router = useRouter();
  const { setCookiesPreferences } = useContext(CookiesPreferencesContext);
  const { setUserResponse } = useUserContext();
  const { setTheme } = useUIContext();

  // States
  const [showCookiesConsent, setShowCookiesConsent] = useState(false);

  const getCSRFToken = async () => {
    try {
      const response = await api.auth_get_csrf_token.fetch();
      return response.success;
    } catch (error) {
      // Network and 500 errors caught by the interceptor
    }
  };

  const getUserData = async () => {
    try {
      const response = await api.auth_get_user.fetch();
      setUserResponse({
        isAuthenticated: response.success,
        organisations: response.body.organisations,
      });
      setTheme(response.body.theme);
    } catch (error) {
      // Network and 500 errors caught by the interceptor
    }
  };

  const checkIfRequiredCookiesAreSet = async () => {
    const csrfTokenCookie = getCookie("csrftoken");
    const preferencesCookie = getCookie("preferenceSet");

    if (!preferencesCookie) setShowCookiesConsent(true);
    // Ensures csrfToken is always set if specifically deleted.
    else if (!csrfTokenCookie) await getCSRFToken();

    if (preferencesCookie && !csrfTokenCookie) await getCSRFToken();
  };

  const onClickAllowAllCookies = () => {
    setCookie(
      "preferenceSet",
      "performance:true analytics:true social:true",
      365
    );
    setCookiesPreferences({
      essential: true,
      performance: true,
      analytics: true,
      social: true,
    });
    setShowCookiesConsent(false);
  };

  const onClickAllowCustomCookies = (
    essential,
    performance,
    analytics,
    social
  ) => {
    setCookie(
      "preferenceSet",
      `performance:${performance} analytics:${analytics} social:${social}`,
      365
    );
    setCookiesPreferences({ essential, performance, analytics, social });
    setShowCookiesConsent(false);
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    checkIfRequiredCookiesAreSet();
  }, [showCookiesConsent, checkIfRequiredCookiesAreSet]);

  useEffect(() => {
    const handleRouteChange = (url) => {
      // Tracking signup progress is handled in the signup component
      // We will get a double page view if we track it here too.
      // Long term solution is to move away from layout switching based on state
      // and towards full routing between pages Then routing can be tracked at a
      // gloval level.

      if (url === "/signup") return;

      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <div className={`h-screen w-screen`}>
      {showCookiesConsent && (
        <CookiePreference
          onClickAllowAll={onClickAllowAllCookies}
          onClickAllowCustom={onClickAllowCustomCookies}
        />
      )}
      <NavigationLoading />
      <Navbar />
      <main>{children}</main>
      <ServerErrorBanner />
      <Footer />
    </div>
  );
};

export default Layout;
