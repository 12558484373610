type LargeLinkProps = {
  label: string;
  route: string;
  goToRoute: (route: string) => void;
};

const LargeLink = ({ label, route, goToRoute }: LargeLinkProps) => {
  const click = (e, route) => {
    e.preventDefault();
    goToRoute(route);
  };

  return (
    <a
      href={route}
      onClick={(e) => click(e, route)}
      className={
        "font-serif font-black text-heading3 sm:text-heading2 text-white leading-[45px]"
      }
    >
      {label}
    </a>
  );
};

export default LargeLink;
