type SmallLinkProps = {
  label: string;
  route: string;
  goToRoute: (route: string) => void;
};

const SmallLink = ({ label, route, goToRoute }: SmallLinkProps) => {
  const click = (e, route) => {
    e.preventDefault();
    goToRoute(route);
  };

  return (
    <a
      href={route}
      className="font-serif font-black text-heading4 text-white leading-[30px]"
      onClick={(e) => click(e, route)}
    >
      {label}
    </a>
  );
};

export default SmallLink;
