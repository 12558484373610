import { store } from "store";

export const assertHasServerError = (
  hasServerError,
  serverErrorCode,
  message,
  override = undefined
) => {
  store.dispatch({
    type: "ui/hasServerError",
    payload: { hasServerError, serverErrorCode, message, override },
  });
};
