const PinwheelPrimaryLogo = ({ width = "100%", height = "100%" }) => {
  // Warning - not setting width or height may cause odd positioning behaviour when the parent is a flex container.
  return (
    <svg
      className="primary_pinwheel_logo"
      width={width}
      height={height}
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M92.0955 78.1079L140 95.5615L118.09 62.2635C114.253 56.3218 106.826 53.8462 100.265 56.0743L77.9841 47.9045L95.3139 0L62.1397 21.9098C56.1981 25.8709 53.7224 33.1742 55.9505 39.8585L47.9045 62.0159L0 44.5623L21.9098 77.7365C25.7471 83.6782 33.0504 86.0301 39.611 83.9257L62.0159 92.0955L44.6861 140L77.8603 118.09C83.8019 114.253 86.1539 106.826 84.0495 100.265L92.0955 78.1079ZM96.9231 57.4359L69.9381 70.0619L77.1176 50.3802L96.9231 57.4359ZM57.4359 43.2007L69.9381 70.0619L50.2564 62.8824L57.4359 43.2007ZM42.9531 82.5641L69.9381 69.9381L62.7586 89.7436L42.9531 82.5641ZM69.9381 70.0619L89.7436 77.2414L82.5641 97.0469L69.9381 70.0619Z"
        fill="white"
      />
    </svg>
  );
};

export default PinwheelPrimaryLogo;
