import initialState from "store/state";

const reducer = (state = initialState, action) => {
  switch (action.type) {
    // Forgot password progress & flow
    case "auth/forgotPasswordData":
      return {
        ...state,
        forgotPasswordData: action.forgotPasswordData,
      };
    case "auth/forgotPasswordProgress":
      return {
        ...state,
        forgotPasswordProgress: action.forgotPasswordProgress,
      };

    // Set the network error popup and relevant states
    case "ui/hasServerError":
      return {
        ...state,
        hasServerError: action.payload.hasServerError,
        serverErrorCode: action.payload.serverErrorCode ?? "",
        message: action.payload.message,
        override: action.payload.override,
      };

    default:
      return state;
  }
};

export default reducer;
