import { useState } from "react";

import Link from "next/dist/client/link";

import { Theme } from "types/ui";

import { getTransitions } from "helpers/utils/uiSetup";

import Reveal, { RevealModes } from "components/animation/Reveal";
import Button from "components/buttons/Button";
import ToggleSwitch from "components/buttons/toggleSwitch";

const CookiePreference = ({ onClickAllowAll, onClickAllowCustom }) => {
  const [isBasePanel, setIsBasePanel] = useState(true);
  const [essential, setEssential] = useState(true);
  const [performance, setPerformance] = useState(true);
  const [analytics, setAnalytics] = useState(true);
  const [social, setSocial] = useState(true);

  return (
    <div
      className={
        `fixed w-screen h-screen bg-white bg-opacity-0 z-30 ` +
        `overflow-scroll flex items-end justify-center p-10 ` +
        `${isBasePanel ? "items-end" : "items-center"}`
      }
    >
      <div
        className={
          `transition-all duration-300 ` +
          `shadow-30px-navy-60 rounded-lg mx-auto text-white ` +
          `bg-navy border-white border ${
            isBasePanel
              ? `flex-1 space-y-10 max-w-7xl h-50 p-7 `
              : `max-h-full max-w-3xl p-10 ` +
                `overflow-auto px-6 sm:px-16 py-8 sm:py-10 `
          }`
        }
      >
        <Reveal>
          {isBasePanel ? (
            <div className="flex flex-col md:flex-row md:items-center gap-5">
              <div className="flex-1">
                We use{" "}
                <Link href="/privacy">
                  <a className="cursor-pointer underline">cookies</a>
                </Link>{" "}
                to make Pinwheel work, personalise content, and analyse site
                traffic.{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={() => setIsBasePanel(false)}
                >
                  Customize
                </span>
              </div>
              <div>
                <Button
                  text="Allow cookies"
                  onClick={onClickAllowAll}
                  borderColor={Theme.wilderness}
                  textColor="white"
                />
              </div>
            </div>
          ) : (
            <Reveal isActive={isBasePanel} mode={RevealModes.opacity}>
              <div className={`space-y-10 divide-y divide-white`}>
                <h2 className="text-white font-black text-heading2">
                  Select Cookies Preferences
                </h2>
                <div className="essential space-y-2 pt-10">
                  <div className="title flex justify-between items-center">
                    <p className="text-sm sm:text-base font-bold">Essential</p>
                    <ToggleSwitch
                      disabled={true}
                      dark={true}
                      value={essential}
                      onChange={setEssential}
                    ></ToggleSwitch>
                  </div>
                  <div className="text w-11/12">
                    <p className="text-sm sm:text-base leading-[28px]">
                      These cookies are strictly necessary to provide you with
                      services available through our Website and to use some of
                      its features, such as access to secure areas. Because
                      these cookies are strictly necessary to deliver the
                      Website to you, you cannot refuse them.
                    </p>
                  </div>
                </div>
                <div className="performance pt-10 space-y-2">
                  <div className="title flex justify-between items-center">
                    <p className="text-sm sm:text-base font-bold">
                      Performance and Functionality Cookies
                    </p>
                    <ToggleSwitch
                      dark={true}
                      value={performance}
                      onChange={setPerformance}
                    ></ToggleSwitch>
                  </div>
                  <div className="text w-11/12">
                    <p className="text-sm sm:text-base leading-[28px]">
                      These cookies are used to enhance the performance and
                      functionality of our Website but are non-essential to
                      their use. However, without these cookies, certain
                      functionality may become unavailable.
                    </p>
                  </div>
                </div>
                <div className="analytics pt-10 space-y-2">
                  <div className="title flex justify-between items-center">
                    <p className="text-sm sm:text-base font-bold">
                      Analytics & Customisation Cookies
                    </p>
                    <ToggleSwitch
                      dark={true}
                      value={analytics}
                      onChange={setAnalytics}
                    ></ToggleSwitch>
                  </div>
                  <div className="text w-11/12">
                    <p className="text-sm sm:text-base leading-[28px]">
                      These cookies collect information that is used either in
                      aggregate form to help us understand how our Website are
                      being used or how effective are marketing campaigns are,
                      or to help us customise our Website for you.
                    </p>
                  </div>
                </div>
                <div className="social pt-10 space-y-2">
                  <div className="title flex justify-between items-center">
                    <p className="text-sm sm:text-base font-bold">
                      Social Networking Cookies
                    </p>
                    <ToggleSwitch
                      dark={true}
                      value={social}
                      onChange={setSocial}
                    ></ToggleSwitch>
                  </div>
                  <div className="text w-11/12">
                    <p className="text-sm sm:text-base leading-[28px]">
                      These cookies are used to enable you to share pages and
                      content that you find interesting on our Website through
                      third party social networking and other websites. These
                      cookies may also be used for advertising purposes too.
                    </p>
                  </div>
                </div>
                <div className="save-preference py-16 flex items-center justify-center">
                  <button
                    onClick={() =>
                      onClickAllowCustom(
                        essential,
                        performance,
                        analytics,
                        social
                      )
                    }
                    className="focus:outline-none border-2 py-2 px-10 rounded-full border-wilderness"
                    style={{ transition: getTransitions("border") }}
                  >
                    Save preferences
                  </button>
                </div>
              </div>
            </Reveal>
          )}
        </Reveal>
      </div>
    </div>
  );
};

export default CookiePreference;
