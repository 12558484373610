import { useState } from "react";

import { OrganisationType } from "types/organisations";

import { useUIContext } from "contexts/UIContext";
import { useUserContext } from "contexts/UserContext";

import { Colors } from "helpers/utils/uiSetup";

import LinkWithLoading from "components/buttons/LinkWithLoading";
import MenuIcon from "components/images/MenuIcon";
import PinwheelDarkLogo from "components/logo/PinwheelDarkLogo";
import PinwheelPrimaryLogo from "components/logo/PinwheelPrimaryLogo";
import NavbarOrganisationLogo from "components/navigation/NavbarOrganisationLogo";
import NavigationMenu from "components/navigation/navbar/NavigationMenu";

const AuthenticatedLinks = ({
  organisation,
}: {
  organisation?: OrganisationType;
}) => (
  <>
    <LinkWithLoading href="/account">Home</LinkWithLoading>
    <LinkWithLoading href="/account/profile">Profile</LinkWithLoading>
    <LinkWithLoading href="/account/action-feed">Action Feed</LinkWithLoading>
    {organisation && (
      <LinkWithLoading href={"/organisations/" + organisation.slug}>
        {`${organisation.name}'s Impact`}
      </LinkWithLoading>
    )}
  </>
);

const UnauthenticatedLinks = () => (
  <>
    <LinkWithLoading href="/projects">Projects</LinkWithLoading>
    <LinkWithLoading href="/stories">Stories</LinkWithLoading>
    <LinkWithLoading href="/login" className="font-semibold">
      Login
    </LinkWithLoading>
  </>
);

const Navbar = () => {
  // Variables
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);
  const {
    navbarColor,
    navbarLogo,
    navbarMobileHamburgerIconColor,
    navbarTabletHamburgerIconColor,
  } = useUIContext();

  const { isAuthenticated, mainOrganisation } = useUserContext();

  return (
    <nav>
      <NavigationMenu
        isActive={isMobileMenuActive}
        setIsActive={setIsMobileMenuActive}
      />
      <div className={"absolute py-5 sm:py-10 w-full z-20"}>
        <section className="flex justify-between items-center mx-auto max-w-7xl px-5 sm:px-10 md:px-15">
          <div className="flex items-center">
            <LinkWithLoading href={isAuthenticated ? "/account" : "/"}>
              <div className="cursor-pointer w-11 sm:w-16">
                {navbarLogo === "primary" && <PinwheelPrimaryLogo />}
                {navbarLogo === "dark" && <PinwheelDarkLogo />}
              </div>
            </LinkWithLoading>
            <NavbarOrganisationLogo />
          </div>
          <div
            className={
              `nav-items text-${navbarColor} text-body16 flex ` +
              `justify-between items-center space-x-16`
            }
          >
            <div className="hidden lg:flex justify-between space-x-10 lg:space-x-16">
              {isAuthenticated ? (
                <AuthenticatedLinks organisation={mainOrganisation} />
              ) : (
                <UnauthenticatedLinks />
              )}
            </div>
            <div
              onClick={() => {
                setIsMobileMenuActive(!isMobileMenuActive);
              }}
              className="flex items-center cursor-pointer"
            >
              <div className="block sm:hidden">
                <MenuIcon color={Colors[navbarMobileHamburgerIconColor]} />
              </div>
              <div className="hidden sm:block">
                <MenuIcon color={Colors[navbarTabletHamburgerIconColor]} />
              </div>
            </div>
          </div>
        </section>
      </div>
    </nav>
  );
};

export default Navbar;
