import { useRouter } from "next/router";

import { useUIContext } from "contexts/UIContext";

import { ContentfulImage } from "components/images/ContentfulImage";

const NavbarOrganisationLogo = () => {
  const { navbarOrganisationLogo } = useUIContext();
  const { pathname } = useRouter();

  if (!pathname.startsWith("/organisations") || !navbarOrganisationLogo)
    return null;

  return (
    <div className="border-l ml-7 pl-7">
      <div className="w-11 sm:w-16">
        <ContentfulImage
          image={navbarOrganisationLogo}
          width={100}
          height={100}
          layout="responsive"
        />
      </div>
    </div>
  );
};

export default NavbarOrganisationLogo;
