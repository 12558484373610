const apiMessages = {
  wrongEmailConfirmationNewCreated:
    "Invalid code, please try again. A new confirmation code has been sent to your email",
  confirmationCodeIsIncomplete: "Please enter your confirmation code",
  confirmationCodeWasResent:
    "A new confirmation code has been sent to your email",
  confirmationCodeWasResentShort: "New confirmation code sent",
  problemWithInputFields:
    "There's a problem with the information you've provided in one or more input fields.",
};
export default apiMessages;
