import { useUIContext } from "contexts/UIContext";

import { convertUnderScoreToHyphen } from "helpers/utils/formatting";
import { getTransitions } from "helpers/utils/uiSetup";

const NavigationLoading = () => {
  const { theme, isLoading } = useUIContext();
  return (
    <div
      className={
        `z-10 fixed top-0 h-0.5 drop-shadow-md ${
          isLoading ? "w-full" : "w-0"
        } ` + `bg-${convertUnderScoreToHyphen(theme)} `
      }
      style={{ transition: isLoading ? getTransitions("x-loading-tab") : null }}
    />
  );
};

export default NavigationLoading;
